import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentStateService } from '@core/store/current-state.service';
import { UserRole } from '@core/types/user-role';
import { AppRoute } from '../../app-routes.enum';
import * as AuthSelectors from '../store/auth/auth.selectors';

@Injectable()
export class LegacyCountryManagerGuard  {
  constructor(
    private router: Router,
    private currentStateService: CurrentStateService,
  ) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isDECountryManager = this.currentStateService.get<UserRole>(AuthSelectors.userRole) === UserRole.COUNTRY_MANAGER;

    if (!isDECountryManager) {
      this.redirectToLogin(state.url);
    }

    return isDECountryManager;
  }

  // TODO: since this is the same like in other guard, think about extracting that code into a service...
  private redirectToLogin(currentRoute = ''): void {
    const requestedRoute = currentRoute.split('?')[ 0 ];
    this.router.navigate([ AppRoute.DASHBOARD ], { queryParams: { next: requestedRoute } });
  }
}
