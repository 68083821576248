/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountryCode } from '../models/country-code';
import { LanguageCode } from '../models/language-code';
import { OrderReturn } from '../models/order-return';
import { OrderReturnsPage } from '../models/order-returns-page';
import { ReceivedOrderReturn } from '../models/received-order-return';
import { ReturnStatusUpdate } from '../models/return-status-update';

@Injectable({
  providedIn: 'root',
})
export class OrderReturnsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateOrderReturnStatus
   */
  static readonly UpdateOrderReturnStatusPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}/returns/{returnId}/status';

  /**
   * Update order return status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrderReturnStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderReturnStatus$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The return id
     */
    returnId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: ReturnStatusUpdate
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnsService.UpdateOrderReturnStatusPath, 'put');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.path('returnId', params.returnId);
      rb.query('lang', params.lang);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Update order return status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrderReturnStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderReturnStatus(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The return id
     */
    returnId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: ReturnStatusUpdate
  }
): Observable<OrderReturn> {

    return this.updateOrderReturnStatus$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Path part for operation getOrderReturns
   */
  static readonly GetOrderReturnsPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}/returns';

  /**
   * Get order returns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderReturns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturns$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

    /**
     * The requested page of results.
     */
    page?: number;

    /**
     * The number of results to return per-call.
     */
    size?: number;

  }
): Observable<StrictHttpResponse<OrderReturnsPage>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnsService.GetOrderReturnsPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.query('lang', params.lang);
      rb.query('page', params.page);
      rb.query('size', params.size);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturnsPage>;
      })
    );
  }

  /**
   * Get order returns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderReturns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturns(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

    /**
     * The requested page of results.
     */
    page?: number;

    /**
     * The number of results to return per-call.
     */
    size?: number;

  }
): Observable<OrderReturnsPage> {

    return this.getOrderReturns$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturnsPage>) => r.body as OrderReturnsPage)
    );
  }

  /**
   * Path part for operation createOrderReturn
   */
  static readonly CreateOrderReturnPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}/returns';

  /**
   * Create order return.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderReturn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderReturn$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: ReceivedOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnsService.CreateOrderReturnPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.query('lang', params.lang);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Create order return.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderReturn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderReturn(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: ReceivedOrderReturn
  }
): Observable<OrderReturn> {

    return this.createOrderReturn$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Path part for operation getOrderReturnById
   */
  static readonly GetOrderReturnByIdPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}/returns/{returnId}';

  /**
   * Get order return by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderReturnById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnById$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The return id
     */
    returnId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnsService.GetOrderReturnByIdPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.path('returnId', params.returnId);
      rb.query('lang', params.lang);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Get order return by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderReturnById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnById(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The return id
     */
    returnId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

  }
): Observable<OrderReturn> {

    return this.getOrderReturnById$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

}

/**
 * Custom interfaces
 */
export interface UpdateOrderReturnStatusPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=string, file=

    /**
     * The return id
     */
    returnId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
export interface GetOrderReturnsPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

// Debug: type=number, file=

    /**
     * The requested page of results.
     */
    page?: number;

// Debug: type=number, file=

    /**
     * The number of results to return per-call.
     */
    size?: number;
}
export interface CreateOrderReturnPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
export interface GetOrderReturnByIdPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=string, file=

    /**
     * The return id
     */
    returnId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
