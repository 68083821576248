import { Pipe, PipeTransform } from '@angular/core';
import { SellerCenterCurrencyIso } from '@core/config/global.config';
import { NgxCurrencyConfig } from 'ngx-currency';
import { CURRENCY_MASK_MAPPING, CURRENCY_MASK_DEFAULT } from '../util/currency.util';

@Pipe({
  name: 'sssCurrencyConfig',
})
export class CurrencyConfigPipe implements PipeTransform {
  transform(value: SellerCenterCurrencyIso): NgxCurrencyConfig {
    return CURRENCY_MASK_MAPPING[ value ] || CURRENCY_MASK_DEFAULT;
  }
}
