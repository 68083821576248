/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountryCode } from '../models/country-code';
import { GetProductTypeInterests } from '../models/get-product-type-interests';
import { UpdateOrCreateProductTypeInterests } from '../models/update-or-create-product-type-interests';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeInterestsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProductTypeInterest
   */
  static readonly GetProductTypeInterestPath = '/api/sellers/profile/countries/{country}/companies/{companyId}/outlets/{outletId}/productTypes/interests';

  /**
   * Get ProductTypeInterest for Outlet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductTypeInterest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductTypeInterest$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;
    outletId: string;

  }
): Observable<StrictHttpResponse<{
[key: string]: GetProductTypeInterests;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ProductTypeInterestsService.GetProductTypeInterestPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: GetProductTypeInterests;
        }>;
      })
    );
  }

  /**
   * Get ProductTypeInterest for Outlet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductTypeInterest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductTypeInterest(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;
    outletId: string;

  }
): Observable<{
[key: string]: GetProductTypeInterests;
}> {

    return this.getProductTypeInterest$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: GetProductTypeInterests;
}>) => r.body as {
[key: string]: GetProductTypeInterests;
})
    );
  }

  /**
   * Path part for operation updateOrCreateProductTypeInterest
   */
  static readonly UpdateOrCreateProductTypeInterestPath = '/api/sellers/profile/countries/{country}/companies/{companyId}/outlets/{outletId}/productTypes/interests';

  /**
   * Update or Create ProductTypeInterest for Outlet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrCreateProductTypeInterest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateProductTypeInterest$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;
    outletId: string;

      body: UpdateOrCreateProductTypeInterests
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProductTypeInterestsService.UpdateOrCreateProductTypeInterestPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update or Create ProductTypeInterest for Outlet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrCreateProductTypeInterest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateProductTypeInterest(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;
    outletId: string;

      body: UpdateOrCreateProductTypeInterests
  }
): Observable<void> {

    return this.updateOrCreateProductTypeInterest$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getProductTypeInterestsForCompany
   */
  static readonly GetProductTypeInterestsForCompanyPath = '/api/sellers/profile/countries/{country}/companies/{companyId}/productTypes/interests';

  /**
   * Get ProductTypeInterest for Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductTypeInterestsForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductTypeInterestsForCompany$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;

  }
): Observable<StrictHttpResponse<{
[key: string]: GetProductTypeInterests;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ProductTypeInterestsService.GetProductTypeInterestsForCompanyPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: GetProductTypeInterests;
        }>;
      })
    );
  }

  /**
   * Get ProductTypeInterest for Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductTypeInterestsForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductTypeInterestsForCompany(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;

  }
): Observable<{
[key: string]: GetProductTypeInterests;
}> {

    return this.getProductTypeInterestsForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: GetProductTypeInterests;
}>) => r.body as {
[key: string]: GetProductTypeInterests;
})
    );
  }

}

/**
 * Custom interfaces
 */
export interface GetProductTypeInterestPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;

// Debug: type=CountryCode, file=
    country: CountryCode;

// Debug: type=string, file=
    companyId: string;

// Debug: type=string, file=
    outletId: string;
}
export interface UpdateOrCreateProductTypeInterestPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;

// Debug: type=CountryCode, file=
    country: CountryCode;

// Debug: type=string, file=
    companyId: string;

// Debug: type=string, file=
    outletId: string;
}
export interface GetProductTypeInterestsForCompanyPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;

// Debug: type=CountryCode, file=
    country: CountryCode;

// Debug: type=string, file=
    companyId: string;
}
