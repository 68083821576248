/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Flags } from '../models/flags';
import { UserEntitlements } from '../models/user-entitlements';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFlags
   */
  static readonly GetFlagsPath = '/api/sellers/profile/flags';

  /**
   * Get Profile Flags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlags$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;

  }
): Observable<StrictHttpResponse<Flags>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetFlagsPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Flags>;
      })
    );
  }

  /**
   * Get Profile Flags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlags(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;

  }
): Observable<Flags> {

    return this.getFlags$Response(params).pipe(
      map((r: StrictHttpResponse<Flags>) => r.body as Flags)
    );
  }

  /**
   * Path part for operation createFlag
   */
  static readonly CreateFlagPath = '/api/sellers/profile/flags';

  /**
   * Create Profile Flags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlag$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;

      body: Flags
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.CreateFlagPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create Profile Flags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlag(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;

      body: Flags
  }
): Observable<void> {

    return this.createFlag$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getSellerProfile
   */
  static readonly GetSellerProfilePath = '/api/sellers/profile';

  /**
   * Get Seller Profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSellerProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSellerProfile$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;

  }
): Observable<StrictHttpResponse<UserEntitlements>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetSellerProfilePath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserEntitlements>;
      })
    );
  }

  /**
   * Get Seller Profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSellerProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSellerProfile(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;

  }
): Observable<UserEntitlements> {

    return this.getSellerProfile$Response(params).pipe(
      map((r: StrictHttpResponse<UserEntitlements>) => r.body as UserEntitlements)
    );
  }

}

/**
 * Custom interfaces
 */
export interface GetFlagsPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;
}
export interface CreateFlagPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;
}
export interface GetSellerProfilePathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;
}
