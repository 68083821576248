import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyConfigPipe } from './currency-config.pipe';
import { CustomCurrencyPipe } from './currency.pipe';
import { DatePipe } from './date.pipe';
import { KeyValuePipe } from './keyValue.pipe';
import { TimePipe } from './time.pipe';
import { NumberPipe } from './number.pipe';
import { CustomTranslatePipe } from './custom-translate.pipe';

const pipes = [
  DatePipe,
  TimePipe,
  KeyValuePipe,
  CustomCurrencyPipe,
  CurrencyConfigPipe,
  NumberPipe,
  CustomTranslatePipe,
];

@NgModule({
  imports: [],
  providers: [
    CurrencyPipe,
  ],
  declarations: [ ...pipes ],
  exports: [ ...pipes ],
})
export class PipesModule {}
