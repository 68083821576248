/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountryCode } from '../models/country-code';
import { CreateOrderCancellation } from '../models/create-order-cancellation';
import { LanguageCode } from '../models/language-code';
import { Order } from '../models/order';
import { OrdersExport } from '../models/orders-export';
import { OrdersPage } from '../models/orders-page';
import { Tracking } from '../models/tracking';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createOrderTracking
   */
  static readonly CreateOrderTrackingPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}/tracking';

  /**
   * Create order tracking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderTracking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderTracking$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: Tracking
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.CreateOrderTrackingPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.query('lang', params.lang);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create order tracking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderTracking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderTracking(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: Tracking
  }
): Observable<void> {

    return this.createOrderTracking$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation cancelOrder
   */
  static readonly CancelOrderPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}/cancellation';

  /**
   * Cancel order.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelOrder$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: CreateOrderCancellation
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.CancelOrderPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.query('lang', params.lang);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Cancel order.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelOrder(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: CreateOrderCancellation
  }
): Observable<any> {

    return this.cancelOrder$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation createOrdersExport
   */
  static readonly CreateOrdersExportPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/export';

  /**
   * Request orders export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrdersExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrdersExport$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: OrdersExport
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.CreateOrdersExportPath, 'post');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.query('lang', params.lang);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request orders export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrdersExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrdersExport(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

      body: OrdersExport
  }
): Observable<void> {

    return this.createOrdersExport$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrderRefundReasons
   */
  static readonly GetOrderRefundReasonsPath = '/api/sellers/countries/{country}/orders/refund/reasons';

  /**
   * Get reasons to refund an order.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderRefundReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderRefundReasons$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;
    country: CountryCode;

  }
): Observable<StrictHttpResponse<Array<'DAMAGED_IN_TRANSIT' | 'MISMATCH_CUSTOMER_EXPECTATION' | 'OTHER' | 'ITEM_NOT_NEEDED' | 'LATE_DELIVERY' | 'PRICE_MATCH' | 'UNSATISFACTORY_CUSTOMER_SUPPORT' | 'COMPLEX_SETUP' | 'LOST_IN_TRANSIT' | 'MANUFACTURING_FAULT' | 'UNSATISFACTORY_QUALITY' | 'WRONG_DESCRIPTION' | 'WRONG_ITEM_ORDERED' | 'MISSED_LINK_DEAL' | 'WRONG_QUANTITY_ORDERED' | 'MISS_PICK_WRONG_ITEM_DELIVERED' | 'MISS_PICK_ITEM_MISSING' | 'SITE_ERROR' | 'GOOD_WILL'>>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.GetOrderRefundReasonsPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<'DAMAGED_IN_TRANSIT' | 'MISMATCH_CUSTOMER_EXPECTATION' | 'OTHER' | 'ITEM_NOT_NEEDED' | 'LATE_DELIVERY' | 'PRICE_MATCH' | 'UNSATISFACTORY_CUSTOMER_SUPPORT' | 'COMPLEX_SETUP' | 'LOST_IN_TRANSIT' | 'MANUFACTURING_FAULT' | 'UNSATISFACTORY_QUALITY' | 'WRONG_DESCRIPTION' | 'WRONG_ITEM_ORDERED' | 'MISSED_LINK_DEAL' | 'WRONG_QUANTITY_ORDERED' | 'MISS_PICK_WRONG_ITEM_DELIVERED' | 'MISS_PICK_ITEM_MISSING' | 'SITE_ERROR' | 'GOOD_WILL'>>;
      })
    );
  }

  /**
   * Get reasons to refund an order.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderRefundReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderRefundReasons(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;
    country: CountryCode;

  }
): Observable<Array<'DAMAGED_IN_TRANSIT' | 'MISMATCH_CUSTOMER_EXPECTATION' | 'OTHER' | 'ITEM_NOT_NEEDED' | 'LATE_DELIVERY' | 'PRICE_MATCH' | 'UNSATISFACTORY_CUSTOMER_SUPPORT' | 'COMPLEX_SETUP' | 'LOST_IN_TRANSIT' | 'MANUFACTURING_FAULT' | 'UNSATISFACTORY_QUALITY' | 'WRONG_DESCRIPTION' | 'WRONG_ITEM_ORDERED' | 'MISSED_LINK_DEAL' | 'WRONG_QUANTITY_ORDERED' | 'MISS_PICK_WRONG_ITEM_DELIVERED' | 'MISS_PICK_ITEM_MISSING' | 'SITE_ERROR' | 'GOOD_WILL'>> {

    return this.getOrderRefundReasons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<'DAMAGED_IN_TRANSIT' | 'MISMATCH_CUSTOMER_EXPECTATION' | 'OTHER' | 'ITEM_NOT_NEEDED' | 'LATE_DELIVERY' | 'PRICE_MATCH' | 'UNSATISFACTORY_CUSTOMER_SUPPORT' | 'COMPLEX_SETUP' | 'LOST_IN_TRANSIT' | 'MANUFACTURING_FAULT' | 'UNSATISFACTORY_QUALITY' | 'WRONG_DESCRIPTION' | 'WRONG_ITEM_ORDERED' | 'MISSED_LINK_DEAL' | 'WRONG_QUANTITY_ORDERED' | 'MISS_PICK_WRONG_ITEM_DELIVERED' | 'MISS_PICK_ITEM_MISSING' | 'SITE_ERROR' | 'GOOD_WILL'>>) => r.body as Array<'DAMAGED_IN_TRANSIT' | 'MISMATCH_CUSTOMER_EXPECTATION' | 'OTHER' | 'ITEM_NOT_NEEDED' | 'LATE_DELIVERY' | 'PRICE_MATCH' | 'UNSATISFACTORY_CUSTOMER_SUPPORT' | 'COMPLEX_SETUP' | 'LOST_IN_TRANSIT' | 'MANUFACTURING_FAULT' | 'UNSATISFACTORY_QUALITY' | 'WRONG_DESCRIPTION' | 'WRONG_ITEM_ORDERED' | 'MISSED_LINK_DEAL' | 'WRONG_QUANTITY_ORDERED' | 'MISS_PICK_WRONG_ITEM_DELIVERED' | 'MISS_PICK_ITEM_MISSING' | 'SITE_ERROR' | 'GOOD_WILL'>)
    );
  }

  /**
   * Path part for operation getOrderCancelReasons
   */
  static readonly GetOrderCancelReasonsPath = '/api/sellers/countries/{country}/orders/cancel/reasons';

  /**
   * Get reasons to cancel an order.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderCancelReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderCancelReasons$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;
    country: CountryCode;

  }
): Observable<StrictHttpResponse<Array<'CREATED_BY_MISTAKE' | 'OUT_OF_STOCK' | 'LATE_DELIVERY' | 'MODIFY_ORDER' | 'DUPLICATE_ORDER' | 'WAREHOUSE' | 'CUSTOMER_DECISION_CHANGED' | 'CUSTOMER_REQUEST' | 'ALTERNATIVE_ITEM_NEEDED' | 'OTHER' | 'DELIVERY_COST' | 'NA' | 'WRONG_QUANTITY_ORDERED' | 'CHEAP_ALTERNATIVE_FOUND' | 'WRONG_DELIVERY_ADDRESS' | 'WRONG_BILLING_ADDRESS' | 'WRONG_PAYMENT_METHOD' | 'SELLER_NOT_RESPONSIVE' | 'BAD_REVIEWS'>>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.GetOrderCancelReasonsPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<'CREATED_BY_MISTAKE' | 'OUT_OF_STOCK' | 'LATE_DELIVERY' | 'MODIFY_ORDER' | 'DUPLICATE_ORDER' | 'WAREHOUSE' | 'CUSTOMER_DECISION_CHANGED' | 'CUSTOMER_REQUEST' | 'ALTERNATIVE_ITEM_NEEDED' | 'OTHER' | 'DELIVERY_COST' | 'NA' | 'WRONG_QUANTITY_ORDERED' | 'CHEAP_ALTERNATIVE_FOUND' | 'WRONG_DELIVERY_ADDRESS' | 'WRONG_BILLING_ADDRESS' | 'WRONG_PAYMENT_METHOD' | 'SELLER_NOT_RESPONSIVE' | 'BAD_REVIEWS'>>;
      })
    );
  }

  /**
   * Get reasons to cancel an order.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderCancelReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderCancelReasons(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;
    country: CountryCode;

  }
): Observable<Array<'CREATED_BY_MISTAKE' | 'OUT_OF_STOCK' | 'LATE_DELIVERY' | 'MODIFY_ORDER' | 'DUPLICATE_ORDER' | 'WAREHOUSE' | 'CUSTOMER_DECISION_CHANGED' | 'CUSTOMER_REQUEST' | 'ALTERNATIVE_ITEM_NEEDED' | 'OTHER' | 'DELIVERY_COST' | 'NA' | 'WRONG_QUANTITY_ORDERED' | 'CHEAP_ALTERNATIVE_FOUND' | 'WRONG_DELIVERY_ADDRESS' | 'WRONG_BILLING_ADDRESS' | 'WRONG_PAYMENT_METHOD' | 'SELLER_NOT_RESPONSIVE' | 'BAD_REVIEWS'>> {

    return this.getOrderCancelReasons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<'CREATED_BY_MISTAKE' | 'OUT_OF_STOCK' | 'LATE_DELIVERY' | 'MODIFY_ORDER' | 'DUPLICATE_ORDER' | 'WAREHOUSE' | 'CUSTOMER_DECISION_CHANGED' | 'CUSTOMER_REQUEST' | 'ALTERNATIVE_ITEM_NEEDED' | 'OTHER' | 'DELIVERY_COST' | 'NA' | 'WRONG_QUANTITY_ORDERED' | 'CHEAP_ALTERNATIVE_FOUND' | 'WRONG_DELIVERY_ADDRESS' | 'WRONG_BILLING_ADDRESS' | 'WRONG_PAYMENT_METHOD' | 'SELLER_NOT_RESPONSIVE' | 'BAD_REVIEWS'>>) => r.body as Array<'CREATED_BY_MISTAKE' | 'OUT_OF_STOCK' | 'LATE_DELIVERY' | 'MODIFY_ORDER' | 'DUPLICATE_ORDER' | 'WAREHOUSE' | 'CUSTOMER_DECISION_CHANGED' | 'CUSTOMER_REQUEST' | 'ALTERNATIVE_ITEM_NEEDED' | 'OTHER' | 'DELIVERY_COST' | 'NA' | 'WRONG_QUANTITY_ORDERED' | 'CHEAP_ALTERNATIVE_FOUND' | 'WRONG_DELIVERY_ADDRESS' | 'WRONG_BILLING_ADDRESS' | 'WRONG_PAYMENT_METHOD' | 'SELLER_NOT_RESPONSIVE' | 'BAD_REVIEWS'>)
    );
  }

  /**
   * Path part for operation getOutletOrders
   */
  static readonly GetOutletOrdersPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders';

  /**
   * Get outlet orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOutletOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletOrders$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

    /**
     * A list of order statuses for filtering results.
     */
    status?: Array<string>;

    /**
     * A list of order ids for filtering results.
     */
    orderIds?: Array<string>;

    /**
     * The requested page of results.
     */
    page?: number;

    /**
     * The number of results to return per-call.
     */
    size?: number;

  }
): Observable<StrictHttpResponse<OrdersPage>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.GetOutletOrdersPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.query('lang', params.lang);
      rb.query('status', params.status);
      rb.query('orderIds', params.orderIds);
      rb.query('page', params.page);
      rb.query('size', params.size);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrdersPage>;
      })
    );
  }

  /**
   * Get outlet orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOutletOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletOrders(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

    /**
     * A list of order statuses for filtering results.
     */
    status?: Array<string>;

    /**
     * A list of order ids for filtering results.
     */
    orderIds?: Array<string>;

    /**
     * The requested page of results.
     */
    page?: number;

    /**
     * The number of results to return per-call.
     */
    size?: number;

  }
): Observable<OrdersPage> {

    return this.getOutletOrders$Response(params).pipe(
      map((r: StrictHttpResponse<OrdersPage>) => r.body as OrdersPage)
    );
  }

  /**
   * Path part for operation getOutletOrderById
   */
  static readonly GetOutletOrderByIdPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets/{outletId}/orders/{orderId}';

  /**
   * Get outlet order by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOutletOrderById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletOrderById$Response(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.GetOutletOrderByIdPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);
      rb.path('orderId', params.orderId);
      rb.query('lang', params.lang);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Get outlet order by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOutletOrderById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletOrderById(params: {

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

    /**
     * The order id.
     */
    orderId: string;

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

  }
): Observable<Order> {

    return this.getOutletOrderById$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

}

/**
 * Custom interfaces
 */
export interface CreateOrderTrackingPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
export interface CancelOrderPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
export interface CreateOrdersExportPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
export interface GetOrderRefundReasonsPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=
    country: CountryCode;
}
export interface GetOrderCancelReasonsPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=
    country: CountryCode;
}
export interface GetOutletOrdersPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;

// Debug: type=Array&lt;string&gt;, file=

    /**
     * A list of order statuses for filtering results.
     */
    status?: Array<string>;

// Debug: type=Array&lt;string&gt;, file=

    /**
     * A list of order ids for filtering results.
     */
    orderIds?: Array<string>;

// Debug: type=number, file=

    /**
     * The requested page of results.
     */
    page?: number;

// Debug: type=number, file=

    /**
     * The number of results to return per-call.
     */
    size?: number;
}
export interface GetOutletOrderByIdPathParams {

// Debug: type=string, file=

    /**
     * The client&#x27;s application name.
     */
    ApplicationName: string;

// Debug: type=string, file=

    /**
     * The timestamp when the api call was made.
     */
    Timestamp: string;

// Debug: type=string, file=

    /**
     * A unique uuid to represent the client&#x27;s application.
     */
    CorrelationId: string;

// Debug: type=string, file=

    /**
     * A unique uuid that should be generated by our client for each request made to our API.
     */
    TrackingId: string;

// Debug: type=CountryCode, file=

    /**
     * The seller&#x27;s ISO 3166-1 alpha-2 country code.
     */
    country: CountryCode;

// Debug: type=string, file=

    /**
     * The seller&#x27;s company id.
     */
    companyId: string;

// Debug: type=string, file=

    /**
     * The seller&#x27;s outlet id.
     */
    outletId: string;

// Debug: type=string, file=

    /**
     * The order id.
     */
    orderId: string;

// Debug: type=LanguageCode, file=

    /**
     * The ISO 639-1 language code used in all translatable fields returned by our API.
     */
    lang: LanguageCode;
}
