import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SellerCenterDomainRouteMap } from '@core/config/global.config';
import { DomainAccessService } from '@core/services/domain-access/domain-access.service';
import { SessionService } from '@core/services/session/session.service';

@Injectable()
export class EntitlementGuard  {
  constructor(
    private readonly sessionService: SessionService,
    private readonly domainAccessService: DomainAccessService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const { routeConfig: { path } } = route;
    const domain = SellerCenterDomainRouteMap[path];
    const isLogged = this.sessionService.isLogged();

    if (isLogged && this.domainAccessService.canAccessDomain(domain)) {
      return true;
    }

    return false;
  }
}
