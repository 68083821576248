import { NgxCurrencyConfig } from 'ngx-currency';
import { CurrencyCode } from '@core/config/global.config';

export const CURRENCY_MASK_DEFAULT = {
  allowNegative: true,
  allowZero: true,
  nullable: true,
  precision: 2,
  align: 'right',
  decimal: ',',
  prefix: '',
  suffix: '',
  thousands: '.',
};

function generateMask(conf: Partial<NgxCurrencyConfig>): NgxCurrencyConfig {
  return { ...CURRENCY_MASK_DEFAULT, ...conf };
}

export const CURRENCY_MASK_MAPPING: Record<CurrencyCode, NgxCurrencyConfig> = {
  [ CurrencyCode.ZAR ]: generateMask({ prefix: 'R ', thousands: ' ', decimal: ',' }),
  [ CurrencyCode.AUD ]: generateMask({ suffix: '$', thousands: ' ', decimal: '.' }),
  [ CurrencyCode.EUR ]: generateMask({ suffix: ' €', thousands: '.', decimal: ',' }),
  [ CurrencyCode.GBP ]: generateMask({ prefix: '£', thousands: ',', decimal: '.' }),
  [ CurrencyCode.USD ]: generateMask({ prefix: '$', thousands: ',', decimal: '.' }),
  [ CurrencyCode.CHF ]: generateMask({ prefix: 'CHF ', thousands: '\'', decimal: '.' }),
  [ CurrencyCode.KRW ]: generateMask({ prefix: '₩ ', thousands: ',', decimal: '.', precision: 0 }),
  [ CurrencyCode.JPY ]: generateMask({ prefix: '¥', thousands: ',', decimal: '.', precision: 0 }),
};
