/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountryCode } from '../models/country-code';
import { OutletInfo } from '../models/outlet-info';
import { SimplifiedOutletInfo } from '../models/simplified-outlet-info';


/**
 * Provides information related to outlets and their hierarchy within a particular company.
 */
@Injectable({
  providedIn: 'root',
})
export class OutletsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOutletsByCompanyId
   */
  static readonly GetOutletsByCompanyIdPath = '/api/sellers/countries/{country}/companies/{companyId}/outlets';

  /**
   * Get Company Outlets.
   *
   * Returns all of the outlets for a company by default. However if the filter is enabled then only returns outlets within the scope of Seller Center.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOutletsByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletsByCompanyId$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;
    filter?: boolean;

  }
): Observable<StrictHttpResponse<{
[key: string]: OutletInfo;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OutletsService.GetOutletsByCompanyIdPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('country', params.country);
      rb.path('companyId', params.companyId);
      rb.query('filter', params.filter);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: OutletInfo;
        }>;
      })
    );
  }

  /**
   * Get Company Outlets.
   *
   * Returns all of the outlets for a company by default. However if the filter is enabled then only returns outlets within the scope of Seller Center.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOutletsByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletsByCompanyId(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    country: CountryCode;
    companyId: string;
    filter?: boolean;

  }
): Observable<{
[key: string]: OutletInfo;
}> {

    return this.getOutletsByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: OutletInfo;
}>) => r.body as {
[key: string]: OutletInfo;
})
    );
  }

  /**
   * Path part for operation getOutletById
   */
  static readonly GetOutletByIdPath = '/api/sellers/companies/{companyId}/outlets/{outletId}';

  /**
   * Get Outlet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOutletById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletById$Response(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    companyId: string;
    outletId: string;

  }
): Observable<StrictHttpResponse<SimplifiedOutletInfo>> {

    const rb = new RequestBuilder(this.rootUrl, OutletsService.GetOutletByIdPath, 'get');
    if (params) {

      rb.header('ApplicationName', params.ApplicationName);
      rb.header('Timestamp', params.Timestamp);
      rb.header('CorrelationId', params.CorrelationId);
      rb.header('TrackingId', params.TrackingId);
      rb.path('companyId', params.companyId);
      rb.path('outletId', params.outletId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimplifiedOutletInfo>;
      })
    );
  }

  /**
   * Get Outlet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOutletById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutletById(params: {
    ApplicationName: string;
    Timestamp: string;
    CorrelationId: string;
    TrackingId: string;
    companyId: string;
    outletId: string;

  }
): Observable<SimplifiedOutletInfo> {

    return this.getOutletById$Response(params).pipe(
      map((r: StrictHttpResponse<SimplifiedOutletInfo>) => r.body as SimplifiedOutletInfo)
    );
  }

}

/**
 * Custom interfaces
 */
export interface GetOutletsByCompanyIdPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;

// Debug: type=CountryCode, file=
    country: CountryCode;

// Debug: type=string, file=
    companyId: string;

// Debug: type=boolean, file=
    filter?: boolean;
}
export interface GetOutletByIdPathParams {

// Debug: type=string, file=
    ApplicationName: string;

// Debug: type=string, file=
    Timestamp: string;

// Debug: type=string, file=
    CorrelationId: string;

// Debug: type=string, file=
    TrackingId: string;

// Debug: type=string, file=
    companyId: string;

// Debug: type=string, file=
    outletId: string;
}
