/* tslint:disable */

/**
 * The intended audience of the document is defined by the context (PRIVATE, BUSINESS).
 */
export enum Context {
  ALL = 'ALL',
  BUSINESS = 'BUSINESS',
  PRIVATE = 'PRIVATE'
}
