import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@core/services/session/session.service';
import { AppRoute } from '../../app-routes.enum';
import { WindowRefService } from '@app/shared/services/window-ref/window-ref.service';

@Injectable()
export class AuthGuard  {
  private readonly OTT_REFERENCE = 'ott';

  constructor(
    private windowRefService: WindowRefService,
    private router: Router,
    private sessionService: SessionService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { url } = state;
    const { queryParams: { next } } = route;
    const isLogged = this.sessionService.isLogged();
    const ottUrl = this.windowRefService.nativeWindow.location.href.split('/');
    let oneTimeToken: string = null;
    ottUrl.forEach(word => {
      if (word.includes(this.OTT_REFERENCE)) {
        const params = new URLSearchParams(window.location.search);
        oneTimeToken = params.get(this.OTT_REFERENCE);
      }
    });

    if (oneTimeToken) {
      this.sessionService.start(oneTimeToken, next);
    }

    // bypass /dashboard route because it is used for login component
    if (isLogged || url.includes(AppRoute.DASHBOARD)) {
      this.sessionService.validateExpiration();
      return true;
    }

    // no token and not logged so redirect to login page
    this.router.navigate([ AppRoute.DASHBOARD ], { queryParams: { next: url } });
    return false;
  }
}
