export { Address, Country, CountryCode, Customer, Delivery, DeliveryPeriod, LanguageCode, MonetaryAmount, Order, SimpleOrder, OrderCosts, OrderLine, OrderLinePrice, OrdersPage, OrderStatus, Outlet, Payment, Product, ProductType, OrdersResultContext } from '@generated/ssso/models';

export enum OrderStatusType {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum OrderPaymentStatus {
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
}

export type OrderReturnStatus = 'CANCELED' |
'LICENSE_REVOCATION_FAILED' |
'REFUND_APPROVAL_PENDING' |
'WAIT' |
'RECEIVED' |
'APPROVAL_PENDING' |
'APPROVING' |
'RECEIVING' |
'CANCELLING' |
'PAYMENT_REVERSED' |
'PAYMENT_REVERSAL_FAILED' |
'TAX_REVERSED' |
'TAX_REVERSAL_FAILED' |
'COMPLETED' |
'REVERSING_PAYMENT' |
'REVERSING_TAX';
